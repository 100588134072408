/**
 * This is a store that hold left panel state
 * It could be one of the ActivePanelId
 *
 * Using useStorage from @vueuse/core allow persistance storage accross tabs/sessions
 *
 * We can import and set activePanel anywhere in our project
 * @see /src/components/partials/toolbars/Toolbar.vue
 * @see /src/components/partials/panels/ActivityPanel.vue
 */

import { acceptHMRUpdate, defineStore } from 'pinia'
import { useSessionStorage } from '@vueuse/core'

export type ActivePanelId = 'none' | 'search' | 'presence' | 'chat' | string

interface IPanelItem {
  panel: string
  data: any
}
interface IPanelStack {
  items: IPanelItem[]
}

export const usePanels = defineStore('panels', () => {
  const active = useSessionStorage<ActivePanelId>('active-panel', 'none')
  const data = useSessionStorage<Record<string, any>>('active-panel-data', {})
  const stack = useSessionStorage<IPanelStack>('panel-stack', { items: [] })

  function unshift_stack(item: IPanelItem) {
    if (stack.value && stack.value.items) {
      stack.value.items.unshift(item)
    } else {
      stack.value = { items: [item] }
    }
  }
  function shift_stack(): IPanelItem | undefined {
    if (!stack.value || !stack.value.items) {
      stack.value = { items: [] }
    }
    return stack.value.items.shift()
  }

  function setActive(
    panelId: ActivePanelId,
    panelData?: Record<string, any>,
    onStack = false
  ) {
    if (onStack && active.value !== 'none') {
      unshift_stack({ panel: active.value, data: data.value })
    }
    active.value = panelId
    data.value = panelData || {}
  }

  function close(clearStack = false) {
    if (clearStack) {
      stack.value = { items: [] }
    }
    const item = shift_stack()
    if (item) {
      active.value = item.panel
      data.value = item.data
    } else {
      active.value = 'none'
      data.value = {}
    }
  }

  return {
    active,
    data,
    setActive,
    close,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePanels, import.meta.hot))
}
